<template>
  <div>
      <div class="content">
        <div class="selcont">
          <select v-model="tab" name="fruit">
            <option value="1">标题</option>
            <option value="2">内容</option>
          </select>
        </div>
        <input type="text" class="enter" v-model="searchname"  @keyup.enter="getsearch(searchname,cur.toString())">
        <img @click="getsearch(searchname,cur.toString())" src="../../assets/img/sousuo1.png" class="reuimg">
        <div class="result">相关搜索结果为{{alltotal}}个</div>
        <div class="list">
          <ul>
            <li class="flex" v-for="(item,idx) in list" :key="idx" @click="son(item)">
              <div><img :src="item.icon"></div>
              <div>
               <text-highlight class="infor" :queries="searchname">{{ item.title }}</text-highlight>
                <div class="flex source">
                  <p v-if="item.sourceValue !='' ||item.websetName !=''">来源:{{item.sourceValue==''? item.websetName:item.sourceValue}}</p>
                  <p v-if="item.sourceValue =='' &&item.websetName ==''">来源:本网站</p>
                  <p>发布时间:<span>{{item.releaseTime.slice(0,16)}}</span></p>
                </div>
              </div>
            </li>
          </ul>
          <!-- <div v-if="list.length==0" cla>暂无数据</div> -->
        </div>
        <div class="Pagination">
          <ul class="flex">
            <li v-if="cur>1"><a @click="cur--,pageClick()">上一页</a></li>
            <li v-if="cur==1"><a class="banclick">上一页</a></li>
            <li v-for="idx in indexs" :key="idx" :class="{ 'active': cur == idx}">
            <a @click="btnClick(idx)">{{ idx }}</a>
            </li>
            <li v-if="cur!=all"><a @click="cur++,pageClick()">下一页</a></li>
            <li v-if="cur == all"><a class="banclick">下一页</a></li>
            <li><a>共<i>{{all}}</i>页</a></li>
          </ul>
        </div>  
      </div>
    <Foot></Foot>
  </div>
</template>

<script src="https://cdn.staticfile.org/vue/2.2.2/vue.min.js"></script>
<script>
import { getqueryInfor } from '@/api/home1.js'
import Foot from '../components/footer'
export default {
  components: { 
    Foot,
  },
  data(){
    return{
      all: 10, //总页数
      cur: 1, //当前页码
      totalPage: 0, //当前条数
      searchname:'',
      list:'',
      alltotal:'',
      tab:'',
      tab1:'',
      show1:0,
      over:0,
    }
  },
  methods:{
    getsearch(name,idx){
      if(this.tab==1){
        getqueryInfor({
          title:name,
          size:10,
          current:idx
        }).then((res) => {
          this.list=res.data.records
          this.all=res.data.pages
          this.cur=res.data.current
          this.alltotal=res.data.total
        })
      }else if(this.tab==2){
        getqueryInfor({
          content:name,
          size:10,
          current:idx
        }).then((res) => {
          this.list=res.data.records
          this.all=res.data.pages
          this.cur=res.data.current
          this.alltotal=res.data.total
        })
      }
    },
    dataList (name,idx) {
      this.getsearch(name,idx)
    },
    btnClick(data){        
      if(data != this.cur){ //判断是不是当前页，不是就计算
        this.cur = data 
      }
      this.dataList(this.searchname,this.cur.toString())
    },
    pageClick () {
      this.dataList(this.searchname,this.cur.toString())
    },
    son(item){
      let routerJump = this.$router.resolve({
        path: '/domesticDetails',
        query: {
          // columnNameId: this.columnNameId,
          titleId:item.id,
          // columid:this.columid
        }
      })
      window.open(routerJump.href, '_blank');
    }
  },
  mounted () {
    this.searchname=this.$route.query.searchname
    this.tab=this.$route.query.tab
    this.getsearch(this.searchname,this.cur.toString())
  },
  computed: {
    //上一张
    indexs() {
        var left = 1; //默认起始值是1
        var right = this.all; //终止值是全部
        var arr = [];
        if(this.all>= 5){
            if(this.cur > 3 && this.cur < this.all-2){
                    left = this.cur - 2
                    right = this.cur + 2
            }else{
                if(this.cur<=3){
                    left = 1
                    right = 5
                }else{
                    right = this.all
                    left = this.all -4
                }
            }
        }
        while (left <= right){
            arr.push(left)
            left ++
        }
        return arr
    }
  },
  filters: {
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 32) {
        return value.slice(0,32) + '...'
      }
      return value
    }
  },
}
</script>

<style scoped>
.flex{
  display: flex;
}
.list li div:nth-child(2){
  width: 80%;
}
.list img{
  width: 130px;
  height: 90px;
  margin-right: 30px;
}
.list li{
    position: relative;
    border-bottom: 1px solid #bfbfbf;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
}
.list p{
    width: 100%;
}
.list .infor{
    margin: 10px 0;
    color: #000;
}
.list .source{
    position: absolute;
    bottom: 10px;
    color: #7f7f7f;
}
.Pagination{
  margin: 40px auto;
  margin-top:50px;
}
.Pagination ul{
  justify-content: center;
}
.Pagination li:first-child>a{
  margin-left: 0px;
}
.Pagination a{
  border: 1px solid #000;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.Pagination a:hover{
  background-color: #eee;
}
.Pagination a.banclick{
  cursor: not-allowed;
}
.Pagination .active a{
  color: #fff;
  cursor: default;
  background-color: #51d5c3;
  border-color: #51d5c3;
}
.Pagination i{
  font-style: normal;
  color: #51d5c3;
  margin: 0px 4px;
  font-style: 12px;
}
.content{
  width: 1200px;
  margin: 0 auto;
}
.result{
  margin: 20px 0;
  color: #7F7F7F;
}
.enter{
  border: 1px solid #717171;
  border-radius: 30px;
  width: 600px;
  height: 50px;
  padding-left:20px;
  padding-right: 40px;
  font-size: 18px;
  margin: 20px 0;
  margin-left: 360px;

}
select{
   position: absolute;
   top: 30px;
   font-size: 20px;
}
.selcont{
  position: relative;
  left: 290px;
}
.reuimg{
  position: relative;
  top: 5px;
  left: -40px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>